import React from "react"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import { graphql } from "gatsby"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const NotFound = ({ data, location }) => {
    let global = data.contentfulWebsite;
    return (
        <Layout pathname={location.pathname}>
            <Seo metaTitle={global.title} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
            <div className="o-wrapper o-content o-container o-content-container u-text-center">
                {data.contentfulAboutPage.videoUrl ? (
                    <div className="c-video">
                        <iframe title="carousel-video" className="c-video__frame u-img-border" src={`https://www.youtube.com/embed/${data.contentfulAboutPage.videoUrl.substring(data.contentfulAboutPage.videoUrl.lastIndexOf('/') + 1)}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                ) : ""}
                <div className="h2">{data.contentfulAboutPage.primaryContent.primaryContent}</div>
            </div>
        </Layout>
    );
};

export default NotFound

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "Black Ice"}) {
        author
        description
        title
        pageNotFoundMessage {
            raw
        }
        shareImage {
            file {
                url
            }
        }
    }
    contentfulAboutPage(aboutId: {eq: "Trailer"}, website: {title: {eq: "Black Ice"}}) {
        primaryContent {
            primaryContent
        }
        videoUrl
    }
}
`;